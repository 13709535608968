import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IFactory {
    id: number;
    supplierID: number;
    name: string;

    guid: string;
}

export class Factory implements IFactory {

    constructor(data?: IFactory) {
        if (data) this.update(data);
    }

    update(data: IFactory): void {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    supplierID: number = 0
    name: string = "";

    guid: string = utils.emptyGuidValue;
}