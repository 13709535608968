import { IFactory, Factory } from "../../Factory";
import { mapData } from "@/utilities/DataMapping";
import { Address, IAddress } from "@/model/Address";

export interface IFactoryVM {
    factory: IFactory;
    address: IAddress;
}

export class FactoryVM implements IFactoryVM {

    constructor(data?: IFactoryVM) {
        if (data) this.update(data);
    }

    update(data: IFactoryVM): void {
        mapData(data, { 
            root: () => this,
            factories: () => new Factory(),
            address: () => new Address()
        });
    }

    factory: Factory = new Factory();
    address: Address = new Address();
}