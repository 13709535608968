

























import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import SupplierDialogue from "@/vue/components/dialogues/SupplierDialogue.vue";
import utils from "@/utilities/Utils";
import { SearchParameters } from "@/model/Api/SearchParams/SearchParameters";
import { StateChanger } from "vue-infinite-loading";
import { SupplierVM, ISupplierVM } from "@/model/Api/VM/SupplierVM";
import { Watch } from "vue-property-decorator";

@Component({
    components: { 
        ApiButton,
        SupplierDialogue
    }
})

export default class Suppliers extends Vue {
    
    //
    // -- properties
    //

    private supplierHeaders = [
        { text: "Ref", value: "supplier.reference", sortable: true },
        { text: "Name", value: "supplierAddress.name", sortable: true },
        { text: "Address", value: "supplierAddress.address1", sortable: false },
        { text: "Factories", value: "factoryCount", sortable: false, align: "center" },
        { text: "Has Default Printers", value: "hasDefaultPrinters", sortable: true, align: "center" }   
    ];

    private infiniteId: number = + new Date();
    private searchParameters = new SearchParameters();
    private supplierList: Array<SupplierVM> = [];

    //
    // -- Watchers
    //

    private debouncedRefreshSearch = utils.debounce(this.refreshSearch, 200);

    @Watch("searchParameters.searchText")
    private onSearchTextChanged() {
        this.debouncedRefreshSearch();
    }

    //
    // -- methods
    //

    addNew(): void {
        const dialog: SupplierDialogue = this.$refs.supplierDialogue as SupplierDialogue;
        dialog.add();
    }

    edit(supplier: ISupplierVM): void {
        const dialog: SupplierDialogue = this.$refs.supplierDialogue as SupplierDialogue;
        dialog.edit(supplier.supplier.id);
    }

    refreshSearch(): void {
        this.supplierList = [];
        this.searchParameters.pageNumber = 1;
        this.infiniteId += 1;
    }

    async infiniteLoadingHandler(stateChanger: StateChanger): Promise<void> {
        const response = await apiClient.post("/api/supplier/search", this.searchParameters);
        if (response.list.length) {
            this.supplierList.push(...response.list.map((s: ISupplierVM) => new SupplierVM(s)));
            this.searchParameters.pageNumber += 1;
            stateChanger.loaded();
        }
        else {
            stateChanger.complete();
        }         
    }
}
