
























































































































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ConfirmDialogue from "@/vue/components/dialogues/ConfirmDialogue.vue";
import FactoryDialogue from "@/vue/components/dialogues/FactoryDialogue.vue";
import utils from "@/utilities/Utils";
import { Factory } from "@/model/Factory";
import { FactoryVM } from "@/model/Api/VM/FactoryVM";
import { IPrinterVM, PrinterVM } from "@/model/Api/VM/PrinterVM";
import { ISaveResponse } from "@/model/ISaveResponse";
import { ISupplierVM, SupplierVM } from "@/model/Api/VM/SupplierVM";
import { ISupplierCountryPrinterVM, SupplierCountryPrinterVM } from "@/model/Api/VM/SupplierCountryPrinterVM";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import { Watch } from "vue-property-decorator";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton,
        ConfirmDialogue,
        FactoryDialogue
    } 
})

export default class SupplierDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("supplierForm") private readonly supplierForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private selectedTab: string = "supplier";
    private supplier: SupplierVM = new SupplierVM();
    private factoryToRemove: Factory = new Factory();
    private defaultPrinters: Array<SupplierCountryPrinterVM> = [];
    private availablePrinters: Array<IPrinterVM> = [];
    private isDirty: boolean = false;

    get factoryCount(): number {
        var retVal = 0;

        this.supplier.factories.forEach(f => {
            retVal += 1;
        })

        return retVal;
    }

    //
    // -- validations
    //

    private referenceErrorMessages: Array<string> = [];

    @Watch("supplier.supplier.reference")
    private async validateReference() {
        this.referenceErrorMessages = [];
        if (utils.isEmptyOrWhitespace(this.supplier.supplier.reference))  return;
        const response: { isInUse: boolean } = await apiClient.get(`/api/supplier/referenceIsInUse?reference=${this.supplier.supplier.reference}&supplierID=${this.supplier.supplier.id}`);
        if (response.isInUse) {
            this.referenceErrorMessages.push("Reference is already in use");
            return;
        }
    }

    //
    // -- methods
    //

    add(): void {
        this.reset();
        this.dialogueTitle = "Add Supplier";
        this.selectedTab = "supplier";
        this.loadDefaultPrinters(0);
        this.loadPrinters();
        this.showDialogue = true;
    }

    async edit(id: number): Promise<void> {
        this.reset();
        this.dialogueTitle = "Edit Supplier";
        this.selectedTab = "supplier";
        this.loadDefaultPrinters(id);
        this.loadPrinters();
        const supplierData: ISupplierVM = await apiClient.get(`api/supplier/Load?id=${id}`);
        this.supplier.update(supplierData);
        this.supplier.factories.forEach(factory => {
            factory.factory.guid = utils.newGuid();
        })
        this.showDialogue = true;
    }

    async loadDefaultPrinters(id: number): Promise<void> {
        this.defaultPrinters = [];
        const response = await apiClient.get(`/api/printer/loadSupplierDefaultPrinters?id=${id}`);
        this.defaultPrinters.push(...response.map((p: ISupplierCountryPrinterVM) => new SupplierCountryPrinterVM(p)));
    }

    async loadPrinters(): Promise<void> {
        this.availablePrinters = [];
        const response = await apiClient.get("/api/printer/loadAll");
        this.availablePrinters.push(...response.map((p: IPrinterVM) => new PrinterVM(p)));
    }

    addNewFactory(): void {
        const dialog: FactoryDialogue = this.$refs.factoryDialogue as FactoryDialogue;
        dialog.add();
    }

    editFactory(factory: FactoryVM): void {
        let tempFactory = new FactoryVM(factory);

        const dialog: FactoryDialogue = this.$refs.factoryDialogue as FactoryDialogue;
        dialog.edit(tempFactory);
    }

    private confirmFactoryDelete(factory: Factory) {
        this.factoryToRemove.update(factory);
        const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
        dialog.show();
    }

    private async doFactoryDelete() {
        // indexOf / findIndex doesn't seem to want to work...
        var index = -1

        for (var i = 0; i < this.supplier.factories.length; i++) {
            if (this.supplier.factories[i].factory.guid === this.factoryToRemove.guid) {
                index = i;
                break;
            }
        }

        this.supplier.factories.splice(index, 1);

        if (this.factoryToRemove.id > 0) {
            await apiClient.post("/api/supplier/factoryDelete", this.factoryToRemove, "factory-delete");
            toastr.warning("Deleted");
            const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
            dialog.hide;
        }
    }

    updateFactory(factory: FactoryVM, isUpdate: boolean): void {
        if (isUpdate) {

            // indexOf / findIndex doesn't seem to want to work...
            var index = -1

            for (var i = 0; i < this.supplier.factories.length; i++) {
                if(this.supplier.factories[i].factory.guid === factory.factory.guid) {
                    index = i;
                    break;
                }
            }

            if (index > -1) {
                this.supplier.factories[index].update(factory);
            }

        }
    }

    pushFactory(factory: FactoryVM): void {
        this.isDirty = true;

        // indexOf / findIndex doesn't seem to want to work...
        var index = -1

        for (var i = 0; i < this.supplier.factories.length; i++) {
            if(this.supplier.factories[i].factory.guid === factory.factory.guid) {
                index = i;
                break;
            }
        }

        if (index == -1) {
            let tempFactory = new FactoryVM(factory);
            this.supplier.factories.push(tempFactory);
        } else {
            let tempFactory = new FactoryVM(factory);
            this.supplier.factories.splice(index, 1);
            this.supplier.factories.splice(index, 0, tempFactory);
        }
    }

    private reset() {
        this.supplier.factories = [];
        utils.resetObject(this.supplier);
        this.supplierForm?.resetValidation();
        this.referenceErrorMessages = [];
        this.availablePrinters = [];
        this.isDirty = false;
    }

    private async save() {
        await this.validateReference();
        const isValid = this.supplierForm.validate() && this.referenceErrorMessages.length === 0;
        if (!isValid) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        const postData = {
            supplier: this.supplier,
            supplierCountryPrinters: this.defaultPrinters
        }

        const response: ISaveResponse = await apiClient.post("/api/supplier/save", postData, "supplier-save");
        if (response.isSuccess) {
            toastr.success("Saved");
            this.$emit("refresh");    
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
        
    }

    private cancel() {
        if (this.isDirty) {
            const dialog: ConfirmDialogue = this.$refs.factorySaveConfirmDialogue as ConfirmDialogue;
            dialog.show();
        } else {
            this.showDialogue = false;
        }
    }

    private closeDialogues() {
        const dialog: ConfirmDialogue = this.$refs.factorySaveConfirmDialogue as ConfirmDialogue;
        dialog.hide();
        this.showDialogue = false;
    }
}
