import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface ISupplierCountryPrinter {
    id: number;
    supplierID: number;
    countryID: string;
    defaultPrinterID: string;
}

export class SupplierCountryPrinter implements ISupplierCountryPrinter {

    constructor(data?: ISupplierCountryPrinter) {
        if (data) this.update(data);
    }

    update(data: ISupplierCountryPrinter): void {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    supplierID: number = 0;
    countryID: string = utils.emptyGuidValue;
    defaultPrinterID: string = utils.emptyGuidValue;
}
