import { ISupplierCountryPrinter, SupplierCountryPrinter } from "../../SupplierCountryPrinter";
import { mapData } from "@/utilities/DataMapping";

export interface ISupplierCountryPrinterVM {
    supplierCountryPrinter: ISupplierCountryPrinter;
    country: string;
    countryPrinter: string;
}

export class SupplierCountryPrinterVM implements ISupplierCountryPrinterVM {

    constructor(data?: ISupplierCountryPrinterVM) {
        if (data) this.update(data);
    }

    update(data: ISupplierCountryPrinterVM): void {
        mapData(data, { 
            root: () => this,
            supplierCountryPrinter: () => new SupplierCountryPrinter()
        });
    }

    supplierCountryPrinter: SupplierCountryPrinter = new SupplierCountryPrinter();
    country: string = "";
    countryPrinter: string = "";
}