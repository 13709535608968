






































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import { FactoryVM } from "@/model/Api/VM/FactoryVM";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import utils from "@/utilities/Utils";
import * as toastr from "toastr";

@Component({ components: { ApiButton } })

export default class FactoryDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("factoryForm") private readonly factoryForm!: VForm;

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private saveButtonText: string = "";
    private factory: FactoryVM = new FactoryVM();

    //
    // -- methods
    //

    add(): void {
        utils.resetObject(this.factory);
        this.factory.factory.guid = utils.newGuid();
        this.dialogueTitle = "Add Factory";
        this.saveButtonText = "Add";
        this.showDialogue = true;
    }

    async edit(factory: FactoryVM): Promise<void> {
        utils.resetObject(this.factory);
        this.dialogueTitle = "Edit Factory";
        this.factory.update(factory);
        this.saveButtonText = "Update";
        this.showDialogue = true;
    }

    private async returnFactory() {
        if (!this.factoryForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        this.$emit("pushFactory", this.factory);
        this.factoryForm.reset();
        this.showDialogue = false;
    }
}
