


















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ApiButton from "@/vue/components/ApiButton.vue";

@Component({ components: { ApiButton } })
export default class ConfirmDialog extends Vue {

    //
    // -- properties
    //

    @Prop() private busyEventName?: string;

    @Prop({ default: "Are you sure?" }) private title!: string;
    @Prop({ default: "Are you sure you want to continue?" }) private text!: string;
    @Prop({ default: "Yes, Do It" }) private yesButtonText!: string;
    @Prop({ default: "mdi-check"}) private yesButtonIcon!: string;
    @Prop({ default: "primary" }) private yesButtonColour!: string;
    @Prop({ default: "No, Cancel" }) private noButtonText!: string;
    @Prop({ default: "mdi-cancel" }) private noButtonIcon!: string;
    @Prop({ default: "primary" }) private noButtonColour!: string;

    private showDialog: boolean = false;

    //
    // -- methods
    //

    show(): void {
        this.showDialog = true;
    }

    hide(): void {
        this.showDialog = false;
    }

    private async cancel() {
        await this.$emit("cancel");
        this.showDialog = false;
    }

    private async confirm() {
        await this.$emit("confirm");
        this.showDialog = false;
    }

}
