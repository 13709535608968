import { mapData } from "@/utilities/DataMapping";

export interface ISupplier {
    id: number;
    reference: string;
    name: string;
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    address5: string;
    country: string;
    contact: string;
    email: string;
    phone: string;
}

export class Supplier implements ISupplier {

    constructor(data?: ISupplier) {
        if (data) this.update(data);
    }

    update(data: ISupplier): void {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    reference: string = "";
    name: string = "";
    address1: string = "";
    address2: string = "";
    address3: string = "";
    address4: string = "";
    address5: string = "";
    country: string = "";
    contact: string = "";
    email: string = "";
    phone: string = "";
}