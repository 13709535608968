import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IPrinter {
    id: string;
    reference: string;
    name: string;
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    address5: string;
    countryID: string;
    created: Date;
    lastUpdated: Date;
}

export class Printer implements IPrinter {

    constructor(data?: IPrinter) {
        if (data) this.update(data);
    }

    update(data: IPrinter): void {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    reference: string = "";
    name: string = "";
    address1: string = "";
    address2: string = "";
    address3: string = "";
    address4: string = "";
    address5: string = "";
    countryID: string = utils.emptyGuidValue;
    created: Date = new Date(utils.emptyDateValue);
    lastUpdated: Date = new Date(utils.emptyDateValue);
}