import { IPrinter, Printer } from "../../Printer";
import { mapData } from "@/utilities/DataMapping";

export interface IPrinterVM {
    printer: IPrinter;
    country: string;
    countryDisabled: boolean;
    hasEmails: boolean;
    emails: string;
}

export class PrinterVM implements IPrinterVM {

    constructor(data?: IPrinterVM) {
        if (data) this.update(data);
    }

    update(data: IPrinterVM): void {
        mapData(data, { 
            root: () => this,
            printer: () => new Printer()
        });
    }

    printer: Printer = new Printer();
    country: string = "";
    countryDisabled: boolean = false;
    hasEmails: boolean = false;
    emails: string = "";

    get nameCountry(): string {
        return this.printer.name + " (" + this.country + ")";
    }
}