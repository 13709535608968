import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IAddress {
    id: number;
    name: string;
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    address5: string;
    country: string;
    contact: string;
    email: string;
    phone: string;
    created: Date;
    lastUpdated: Date;
}

export class Address implements IAddress {

    constructor(data?: IAddress) {
        if (data) this.update(data);
    }

    update(data: IAddress): void {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    name: string = "";
    address1: string = "";
    address2: string = "";
    address3: string = "";
    address4: string = "";
    address5: string = "";
    country: string = "";
    contact: string = "";
    email: string = "";
    phone: string = "";
    created: Date = new Date(utils.emptyDateValue);
    lastUpdated: Date = new Date(utils.emptyDateValue);
}
