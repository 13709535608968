import { ISupplier, Supplier } from "../../Supplier";
import { FactoryVM, IFactoryVM } from "./FactoryVM";
import { Factory } from "../../Factory";
import { mapData } from "@/utilities/DataMapping";
import { Address, IAddress } from "@/model/Address";

export interface ISupplierVM {
    supplier: ISupplier;
    supplierAddress: IAddress;
    factories: Array<IFactoryVM>;
    factoryCount: number;
    hasDefaultPrinters: boolean;
}

export class SupplierVM implements ISupplierVM {

    constructor(data?: ISupplierVM) {
        if (data) this.update(data);
    }

    update(data: ISupplierVM): void {
        mapData(data, { 
            root: () => this,
            supplier: () => new Supplier(),
            supplierAddress: () => new Address(),
            factories: () => new FactoryVM(),
            factory: () => new Factory(),
            address: () => new Address()
        });
    }

    supplier: Supplier = new Supplier();
    supplierAddress: Address = new Address();
    factories: Array<FactoryVM> = [];
    factoryCount: number = 0;
    hasDefaultPrinters: boolean = false;
}